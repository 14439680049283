import { IComponentsMetadata } from "../../../types/components";

export const generateComponentInstance = (
  componentBase: IComponentsMetadata,
  componentChildren: IComponentsMetadata
) => {
  let componentInstanceBaseProperties = [];
  componentInstanceBaseProperties = componentBase?.properties.map(
    (property) => {
      return {
        key: property.key,
        value: "",
      };
    }
  );
    // Create an array to hold the transformed categories
    const componentInstanceChldrenProperties = [];

    // Create a map to track unique properties based on column
    const propertyItem = {};

    // Iterate through the input array
    componentChildren?.properties?.forEach(item => {
        const { column, key } = item;

        // Initialize the category if it doesn't exist
        if (!propertyItem[column]) {
          propertyItem[column] = {
                componentType: componentChildren?.type,
                properties: []
            };
            componentInstanceChldrenProperties.push(propertyItem[column]); // Add the new category to the final array
        }

        // Check if the property already exists to avoid duplication
        const existingProperty = propertyItem[column].properties.find(prop => prop.key === key);
        if (!existingProperty) {
            // Add the property to the respective category with a placeholder value
            propertyItem[column].properties.push({ key, value:"" });
        }
    });

  return {
    componentType: componentBase?.type,
    properties: componentInstanceBaseProperties,
    children: componentInstanceChldrenProperties,
  };
};
