import { IComponentsMetadata } from "@/types/components";
import Api from "../../Api/Api";
import { envs } from "../../config/env";
export async function getComponentsCatalog(): Promise<IComponentsMetadata[]> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL_BFF}/component-metadata/catalog`,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}
export async function getComponentById(
  id: string
): Promise<IComponentsMetadata> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL_BFF}/component-metadata/${id}`,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}
export async function getComponentByComponenteMetadataType(
  componenteMetadataType: string
): Promise<IComponentsMetadata> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL_BFF}/component-metadata/?type=${componenteMetadataType}`,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}