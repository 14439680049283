import { useQuery } from "react-query";
import { Pagination } from "../../../../pages/ExperimentsList/ExperimentsList";
import { getExperiments } from "../../../../services/experiment/experimentService";

export const useGetExperimentListQuery = ({ pageSize, page }: Pagination) => {
  const query = useQuery({
    queryFn: () => getExperiments("SUMMARY", pageSize, page),
    queryKey: ["experiments", pageSize, page],
    retry: 5,
    refetchOnWindowFocus: false,
  });
  return query;
};
