import { Button, Image, LoadingDots } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import {
  ComponentsCatalogContent,
  ComponentsCatalogList,
  ComponentsCatalogListLI,
  DialogContentContainer,
} from "./styles";
import { useDispatch } from "react-redux";
import ComponentModalImg from "../../assets/svg/component-modal-img.svg";
import { useGetComponentsCatalogQuery } from "../../hooks/queries/componentMetadata/useGetComponentsCatalogQuery/useGetComponentsCatalogQuery";
import { useEffect, useState } from "react";
import { useGetComponentByComponenteMetadataTypeQuery } from "../../hooks/queries/componentMetadata/useGetComponentByComponenteMetadataTypeQuery/useGetComponentByComponenteMetadataTypeQuery";
import { setComponentInstance } from "../../store/ducks/ComponentInstance/ComponentInstanceDuck";
import { setComponentMetadata } from "../../store/ducks/ComponentMetadataDuck/ComponentMetadataDuck";
import { useComponentMetadata } from "../../store/hooks/ComponentMetadata/ComponentMetadata";
import { IComponentsMetadata } from "../../types/components";
import { generateComponentInstance } from "./utils/utils";
import { useDialog } from "../../context/DialogContext/dialogProvider";
type ComponentsCatalogDialogProps = {
  variantId: string;
};
const ComponentsCatalogDialog = ({
  variantId,
}: ComponentsCatalogDialogProps) => {
  const { componentMetadata } = useComponentMetadata();
  const dispatch = useDispatch();
  const {
    isLoading: isComponentMetadataTypeLoading,
    data: componentMetadataTypeData,
    isSuccess: isComponentMetadataTypeSuccess,
    setComponentChildrenTypeReference,
  } = useGetComponentByComponenteMetadataTypeQuery();
  const {
    isLoading,
    data: componentsCatalogData,
    isSuccess,
  } = useGetComponentsCatalogQuery();
  const [componentTypeReference, setComponentTypeReference] =
    useState<string>("");
    const { closeDialog } = useDialog();
  const handleComponentMetadataType = (type: string) => {
    const componentMetadataItem = componentsCatalogData?.find(
      (item: IComponentsMetadata) => item.type === type
    );
    dispatch(
      setComponentMetadata({
        [variantId]: {
          componentBase: componentMetadataItem,
        },
      })
    );
    setComponentTypeReference(componentMetadataItem.type);
  };
  const handleComponentChildrenMetadataType = () => {
    if (componentMetadata) {
      dispatch(
        setComponentMetadata({
          [variantId]: {
            componentBase: componentMetadata[variantId].componentBase,
            componentChildren: componentMetadataTypeData,
          },
        })
      );
      handleComponentInstance(componentMetadataTypeData);
    }
  };
  const handleComponentInstance = (componentChildren: IComponentsMetadata) => {
    const newComponentInstance = generateComponentInstance(
      componentMetadata[variantId].componentBase,
      componentChildren
    );
    dispatch(
      setComponentInstance({
        [variantId]: newComponentInstance,
      })
    );
    closeDialog();
  };
  useEffect(() => {
    if (isComponentMetadataTypeSuccess) {
      handleComponentChildrenMetadataType();
    }
  }, [componentMetadataTypeData]);
  return (
    <DialogContentContainer>
      <div className="dialog-content">
        {(isLoading || isComponentMetadataTypeLoading) && <LoadingDots />}
        <ComponentsCatalogList>
          <ul>
            <li>
              <h3>
                <FormattedMessage id="modals.component_catalog_side_list_title" />
              </h3>
            </li>
            {isSuccess &&
              componentsCatalogData
                ?.filter((catalog) => catalog.avaliableInCatalog)
                .map((item) => {
                  return (
                    <ComponentsCatalogListLI
                      css={{
                        $$fontWeight:
                          componentTypeReference === item.type ? 600 : 400,
                      }}
                      key={item._id}
                    >
                      <Button
                        className="metadata-item-button"
                        data-testid={item.type}
                        onClick={() => {
                          handleComponentMetadataType(item.type);
                        }}
                      >
                        {item.name}
                      </Button>
                    </ComponentsCatalogListLI>
                  );
                })}
          </ul>
        </ComponentsCatalogList>
        {!componentMetadata ? (
          <ComponentsCatalogContent
            css={{
              $$justifyContent: "center",
              $$alignItems: "center",
            }}
          >
            <Image src={ComponentModalImg} />
            <span>
              <FormattedMessage id="modals.component_catalog_empty_content" />
            </span>
          </ComponentsCatalogContent>
        ) : (
          componentsCatalogData?.map((item: IComponentsMetadata) => {
            if (item.type === componentTypeReference) {
              return (
                <ComponentsCatalogContent
                  css={{
                    $$justifyContent: "flex-start",
                    $$alignItems: "flex-start",
                    $$paddingTop: "0",
                    $$marginLeft: "24px",
                  }}
                  key={`${item._id}-${item.name}`}
                >
                  <h3 style={{ marginTop: "0px" }}>Example</h3>
                  <span data-testid="example-attributes-description">
                    Check an example of the component on the BEES app.
                  </span>
                  <img
                    src={item.catalogPreviewImg}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.catalogAttributesDescription,
                    }}
                  />
                </ComponentsCatalogContent>
              );
            }
          })
        )}
      </div>
      {componentMetadata && (
        <div className="button-actions">
          <Button
            variant="primary"
            onClick={() =>
              setComponentChildrenTypeReference(
                componentMetadata[variantId].componentBase?.childrenMetadata
                  ?.childrenType
              )
            }
          >
            Add
          </Button>
        </div>
      )}
    </DialogContentContainer>
  );
};
export default ComponentsCatalogDialog;