import { styled } from "@hexa-ui/theme";
export const ComponentsCatalogList = styled("div", {
  width: "310px",
  height: "532px",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "rgba(20, 20, 20, 0.04)",
  padding: "0",
  borderRadius: "16px",
  "& ul": {
    width: "262px",
    height: "136px",
    margin: "24px 0 0 24px",
    padding: "0",
    listStyle: "none",
  },
});

export const ComponentsCatalogListLI = styled("li", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "calc(100% / 3)",
  fontSize: "16px",
  lineHeight: "24px",
  border: "$$marginBottom",
  "& .metadata-item-button": {
    fontWeight: "$$fontWeight",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "none",
    color: "#000",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#000",
      border: "none",
      boxShadow: "none",
    },
  },
});
export const ComponentsCatalogContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "$$justifyContent",
  alignItems: "$$alignItems",
  paddingTop: "$$paddingTop",
  marginLeft: "$$marginLeft",
  width: "418px",
  height: "516px",
  "& span": {
    fontFamily: "Work Sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
  },
});

export const DialogContentContainer = styled("div", {
  overflow: "hidden",
  padding: "0",
  display: "flex",
  marginTop: "8px",
  flexDirection: "column",
  "& .dialog-content": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .button-actions": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: "40px",
  },
});

export const InputRadio = styled("input", {
  border: "1px solid #ccc",
  borderRadius: "50%",
  width: "24px",
  height: "24px",
  margin: 0,
  padding: 0,
  outline: "none",
});

export const cssProps = {
  DialogCardCss: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "72px",
    padding: "16px",
    border: "#141414",
    borderRadius: "8px",
  },
};
