import { TextArea } from "@hexa-ui/components";
import { useIntl } from "react-intl";
import { isItValidJson } from "../../pages/ExperimentMutation/utils/";
import { IExperimentationVariation } from "../../types/variation";
import { IFormVariation } from "@/types/experiment";

const JsonInputField = ({
  id,
  label,
  variant,
  name,
  jsonValue,
  changeAction,
  width,
  inputErrors,
  removeErrorVerify
}: {
  id: string;
  label: string;
  variant: IFormVariation; // change it to variant id
  name: string;
  jsonValue: string;
  width?: string | number;
  changeAction: Function;
  inputErrors: boolean;
  removeErrorVerify?: (e:any) => void
}): JSX.Element => {
  const intl = useIntl();

  return (
    <TextArea
      value={jsonValue}
      id={id}
      label={label}
      width={width ?? "1000"}
      onBlur={(e) => isItValidJson(e.target.value)}
      onChange={(e: any) => {
        changeAction(name, e.target.value, variant?.id);
        removeErrorVerify && removeErrorVerify(e);
      }}
      style={{ height: "fit-content" }}
      errorText={`${intl.formatMessage({
        id: `create_and_update_experiment.error_messages.step3.json.invalid_message`,
      })}`}
      hasError={inputErrors}
      data-testid={id}
      name={name}
    />
  );
};

export default JsonInputField;
