import { Divider, Grid } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";
import { ExperimentFormControlCardInput, StepColumnForm } from "../styles";

export const Step3VariantsFormColumn = styled(StepColumnForm, {
  ".item-form-header": {
    width: "100%",
    height: "40px",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "&.margin-config": {
    margin: 0,
    marginTop: "24px",
  },
  ".item-input-row": {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    "&.custom-width": {
      width: "$$customWidth",
    },
    "&.align-items-center": {
      alignItems: "center",
    },
    "&.align-space-between": {
      alignItems: "space-between",
    },
    "&.justify-space-between": {
      justifyContent: "space-between",
    },
    "&.justify-start": {
      justifyContent: "flex-start",
    },
    "&.justify-end": {
      justifyContent: "flex-end",
    },
    "&.custom-width-1255": {
      width: "1255px",
    },
    "&.full-width": {
      width: "100%",
    },
    "&.custom-width-621": {
      width: "621px",
    },
    "&.mt-4": {
      marginTop: "4px",
    },
    "&.mt-24": {
      marginTop: "24px",
    },
    "&.m-32": {
      marginTop: "32px",
    },
    "&.mt-16": {
      marginTop: "16px",
    },
    "&.flex-column": {
      flexDirection: "column",
    },
    "&.container": {
      "&-types": {
        "&-fields": {
          backgroundColor: "rbga(#141414, 0.04)",
          "border-radius": "1rem",
          padding: "16px",
        },
      },
    },
    ".gap-20": {
      gap: "20px",
    },
  },
  ".item-input-column": {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    "&.custom-width": {
      width: "$$customWidth",
    },
    "&.align-items-center": {
      alignItems: "center",
    },
    "&.align-space-between": {
      alignItems: "space-between",
    },
    "&.justify-space-between": {
      justifyContent: "space-between",
    },
    "&.justify-start": {
      justifyContent: "flex-start",
    },
    "&.justify-end": {
      justifyContent: "flex-end",
    },
    "&.custom-width-1255": {
      width: "1255px",
    },
    "&.full-width": {
      width: "100%",
    },
    "&.custom-width-621": {
      width: "621px",
    },
    "&.mt-4": {
      marginTop: "4px",
    },
    "&.mt-24": {
      marginTop: "24px",
    },
    "&.m-32": {
      marginTop: "32px",
    },
    "&.mt-16": {
      marginTop: "16px",
    },
    "&.flex-column": {
      flexDirection: "column",
    },
    "&.container": {
      "&-types": {
        "&-fields": {
          backgroundColor: "rbga(#141414, 0.04)",
          "border-radius": "1rem",
          padding: "16px",
        },
      },
    },
    ".gap-20": {
      gap: "20px",
    },
  },
  ".mt-32": {
    marginTop: "32px",
  },
  ".gap-20": {
    gap: "20px",
  },
  "flex-": {
    "&column": {
      flexDirection: "column",
    },
  },
});

export const StyledContainer = styled("div", {
  backgroundColor: "rgba(20, 20, 20, 0.04)",
  "border-radius": "1rem",
  marginTop: "24px",
  padding: "16px",
  h5: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    textAlign: "left",
  },
});

export const AddVariantArea = styled(Grid.Item, {
  width: "1256px",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "32px",
});

export const HorizontalDivision = styled(Divider, {
  marginTop: "24px",
});

export const Step3FormControlCardInput = styled(ExperimentFormControlCardInput);
