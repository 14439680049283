import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ComponentMetadataItem } from "../../../store/interfaces/componentMetadata/componentMetadata";
const initialStateBase = {
  _id: "",
  name: "",
  type: "",
  description: "",
  variantId: "",
  previewImg: "",
  attributesDescription: "",
  avaliableInCatalog: false,
  rawJson: "",
  keeperUrl: "",
  properties: [],
  childrenMetadata: {
    min: 0,
    title: "",
    alignment: "horizontal" as "horizontal" | "vertical",
    childrenTitles: [],
    childrenType: "",
    childrenPlaceholder: "",
    addChildrenButtonTitle: "",
    removeChildrenButtonTitle: "",
  },
};
export const initialState: ComponentMetadataItem = null;

const componentMetadataSlice = createSlice({
  name: "componentMetadata",
  initialState,
  reducers: {
    setComponentMetadata(state, action: PayloadAction<ComponentMetadataItem>) {
      return { ...state, ...action.payload };
    },

    resetComponentMetadata(state) {
      return initialState;
    },
  },
});

export const { setComponentMetadata, resetComponentMetadata } =
  componentMetadataSlice.actions;

export default componentMetadataSlice.reducer;
