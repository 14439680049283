import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ComponentInstanceItem } from "../../../store/interfaces/componentInstance/componentInstance";

export const initialState: ComponentInstanceItem = null;

const componentInstanceSlice = createSlice({
  name: "componentInstance",
  initialState,
  reducers: {
    setComponentInstance(state, action: PayloadAction<ComponentInstanceItem>) {
      return { ...state, ...action.payload };
    },
    resetComponentInsntace() {
      return initialState;
    },
  },
});

export const { setComponentInstance, resetComponentInsntace } =
  componentInstanceSlice.actions;

export default componentInstanceSlice.reducer;
