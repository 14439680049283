import { getApplicationsSections } from "../../../services/applicationSections/applicationSections";
import { useQuery } from "react-query";

export const useGetApplicationsSectionsEnabledQuery = () => {
  const query = useQuery({
    queryFn: () => getApplicationsSections(),
    queryKey: ["applications-sections"],
    refetchOnWindowFocus: false,
    retry: 5,
  });

  return query;
};
