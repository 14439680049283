import { getComponentsCatalog } from "../../../../services/componentsMetadata/componentsMetadataService";
import { useQuery } from "react-query";

export const useGetComponentsCatalogQuery = () => {
  const query = useQuery({
    queryFn: () => getComponentsCatalog(),
    queryKey: ["componentsCatalog"],
    retry: 5,
    refetchOnWindowFocus: false,
  });
  return query;
};
