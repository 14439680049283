import { useSelector } from "react-redux";
import { ComponentMetadataState } from "../../../store/interfaces/componentMetadata/componentMetadata";

export const useComponentMetadata = () => {
  const componentMetadata = useSelector(
    (state: ComponentMetadataState) => state.componentMetadata
  );

  return {
    componentMetadata,
  };
};
