import Api from "../../Api/Api";
import { envs } from "../../config/env";

export async function getApplicationsSections(): Promise<any> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL}/application-sections`,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}
