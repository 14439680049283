import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getComponentByComponenteMetadataType } from "../../../../services/componentsMetadata/componentsMetadataService";

export const useGetComponentByComponenteMetadataTypeQuery = () => {
  const [componentChildrenTypeReference, setComponentChildrenTypeReference] =
    useState("");
  const query = useQuery({
    queryFn: () =>
      getComponentByComponenteMetadataType(componentChildrenTypeReference),
    queryKey: ["componentType", componentChildrenTypeReference],
    retry: 5,
    enabled: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (componentChildrenTypeReference !== "") {
      query.refetch();
    }
  }, [componentChildrenTypeReference]);

  return {
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    data: query.data,
    setComponentChildrenTypeReference,
  };
};
