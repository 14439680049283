export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isValidExportResponse = (data) => {
  if (
    data &&
    (data?.code?.toString()?.includes("ERR_BAD_RESPONSE") ||
      data?.code?.toString().startsWith("4") ||
      data?.code?.toString().startsWith("5"))
  ) {
    return;
  }

  return true;
};

export const formatToPercentage = (value: string): string => {
  if (!value) {
    return "-";
  }

  return `${Number(value) * 100}%`;
};

export const mountTestId = (testId: string, value: string) => {
  const text = (value.replace(/ /g, "-").replace(/,/g, "").replace(/\(/, "").replace(/\)/, "")).toLowerCase();
  return `${testId}-${text}`;
};

export function lastArrayItem(array) {
  return array[array.length - 1];
}

// utils/imageUtils.js
export const processUploadedImage = (uploadResponseFile, key, setImage, handleParentChange) => {
  if (uploadResponseFile.length === 0) {
    return; // No file uploaded
  }

  const receivedFile = lastArrayItem(uploadResponseFile); // Assuming single file upload
  const fileReader = new FileReader();

  fileReader.onloadend = (event) => {
    const result = event.target.result; // base64 image data
    handleParentChange({ key: key, value: result });
    setImage([receivedFile]);
  };

  fileReader.readAsDataURL(receivedFile.file); // Read file as data URL
};