import {
  Alert,
  Checkbox,
  Divider,
  Grid,
  Heading,
  Input,
  Paragraph,
  TextLink,
} from "@hexa-ui/components";
import { HelpCircle } from "@hexa-ui/icons";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import AudienceSelector from "../../../components/AudienceSelector/AudienceSelector";
import CustomCreateNewAudienceDialog from "../../../components/CustomCreateNewAudienceDialog/CustomCreateNewAudienceDialog";
import { setExperimentMutation } from "../../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import { useExperimentMutation } from "../../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import {
  ExperimentInputErrors,
  IExperimentationAudience,
} from "../../../types/experiment";
import * as ExperimentMutationStyles from "../styles";
import * as Styles from "./styles";
type Props = {
  inputError: string[];
  setinputFormError: React.Dispatch<
    React.SetStateAction<ExperimentInputErrors>
  >;
};

const Step2 = ({ inputError, setinputFormError }: Props): JSX.Element => {
  const { Item } = Grid;

  const intl = useIntl();

  const handleTrafficPercentage = (value: number) => {
    if (value > 100) return "100";
    if (value < 0) return "0";
    return String(value);
  };

  const {
    experimentMutation: {
      audiences,
      basicInformation: { country },
    },
  } = useExperimentMutation();
  const dispatch = useDispatch();

  const handleOnChangeForm = (
    inputName?: string,
    value?: string | number | boolean | IExperimentationAudience
  ) => {
    if (inputName === "trafficPercentage") {
      value = handleTrafficPercentage(Number(value));
    }

    dispatch(
      setExperimentMutation({ audiences: { ...audiences, [inputName]: value } })
    );

    const field =
      (inputName === "countryAsAudience" && value) || inputName === "audiences"
        ? "noAudience"
        : inputName;

    const index = inputError.indexOf(field);
    inputError.splice(index, 1);

    const errors = {
      step1: [],
      step2: inputError,
      step3: {
        errors: false,
        variant_b: [],
        variant_c: [],
        variant_d: [],
        variant_e: [],
        variant_f: [],
        variant_g: [],
      },
    };
    setinputFormError(errors);
  };

  const [openCreateNewAudienceDialog, setOpenCreateNewAudienceDialog] =
    useState(false);

  return (
    <ExperimentMutationStyles.ExperimentStepsWrapper data-testid="step-2">
      <CustomCreateNewAudienceDialog
        openCreateNewAudienceDialog={openCreateNewAudienceDialog}
        setOpenCreateNewAudienceDialog={setOpenCreateNewAudienceDialog}
      />
      <ExperimentMutationStyles.ExperimentStepsCard
        css={{
          $$customMarginTop: "32px",
          $$padding: 0,
          $$height_experiment_steps_wrapper: audiences?.countryAsAudience
            ? "150px"
            : "298px",
        }}
        ref={{
          current: "[Circular]" as any,
        }}
        border="medium"
        elevated="small"
      >
        <ExperimentMutationStyles.ExperimentFormHeader>
          <Heading size="H3">
            <FormattedMessage id="create_and_update_experiment.page_title_section.step2.audience_selection" />
          </Heading>
        </ExperimentMutationStyles.ExperimentFormHeader>

        {inputError?.includes("noAudience") && (
          <Alert
            css={{ width: "100%" }}
            message="You must select an audience"
            type="error"
          />
        )}
        <Styles.Step2AudiencesFormColumn
          className="default-config"
          css={{
            $$step_column_width: "100%",
            $$flex_direction: "column",
          }}
        >
          <Item className="item">
            <Styles.Step2FormControlCardInput
              css={{
                $$width_form_control_input: "463px",
                $$marginTop_form_control_input: "24px",
                $$height_form_control_input: "42px",
                $$justify_content: "space-between",
                $$flex_direction: "row",
              }}
            >
              <Checkbox
                id="checkbox-country-as-audience"
                data-testid="checkbox-country-as-audience"
                label={`${intl.formatMessage({ id: "create_and_update_experiment.audience.selected_country_checkbox" })}`}
                checked={audiences?.countryAsAudience}
                onClick={() =>
                  handleOnChangeForm(
                    "countryAsAudience",
                    !audiences?.countryAsAudience
                  )
                }
                value={audiences?.countryAsAudience?.toString()}
              />

              <Divider orientation="vertical" />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "14px",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  <FormattedMessage id="create_and_update_experiment.labels.step2.selected_country" />
                </span>
                <span style={{ fontWeight: "400" }}>
                  <FormattedMessage id={`countries.${country}.label`} />
                </span>
              </div>
            </Styles.Step2FormControlCardInput>
          </Item>
        </Styles.Step2AudiencesFormColumn>
        {!audiences?.countryAsAudience && (
          <Styles.Step2AudiencesFormColumn
            className="default-config"
            css={{
              $$step_column_width: "100%",
              $$flex_direction: "column",
            }}
          >
            <Item className="item">
              <Styles.Step2FormControlCardInput
                css={{
                  $$width_form_control_input: "890px",
                  $$marginTop_form_control_input: "24px",
                  $$height_form_control_input: "72px",
                  $$justify_content: "space-between",
                  $$flex_direction: "column",
                  fontSize: "14px",
                }}
              >
                <AudienceSelector
                  handleOnChangeForm={handleOnChangeForm}
                  step2Form={{ ...audiences, country }}
                />
                <Item
                  lg={12}
                  md={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 12,
                  }}
                ></Item>
              </Styles.Step2FormControlCardInput>

              <Styles.Step2AudiencesFormColumn
                className="default-config"
                css={{
                  $$step_column_width: "100%",
                  $$flex_direction: "column",
                }}
              >
                <Item className="item">
                  <Styles.Step2FormControlCardInput
                    css={{
                      $$width_form_control_input: "265px",
                      $$marginTop_form_control_input: "23px",
                      $$height_form_control_input: "32px",
                      $$justify_content: "space-between",
                      $$flex_direction: "row",
                      fontSize: "14px",
                      alignItems: "center",
                    }}
                  >
                    <HelpCircle
                      style={{
                        width: "16px",
                        height: "16px",
                        color: "rgb(3,99,196)",
                      }}
                      // style={{
                      //   marginRight: "5px",
                      // }}
                    />
                    <TextLink
                      size="basis"
                      onClick={() => setOpenCreateNewAudienceDialog(true)}
                      hasUnderline={false}
                      data-testid="create-new-audience-link"
                    >
                      <FormattedMessage id="create_and_update_experiment.audience.create_a_new_audience" />
                    </TextLink>
                  </Styles.Step2FormControlCardInput>
                </Item>
              </Styles.Step2AudiencesFormColumn>
            </Item>
          </Styles.Step2AudiencesFormColumn>
        )}
      </ExperimentMutationStyles.ExperimentStepsCard>

      <ExperimentMutationStyles.ExperimentStepsCard
        css={{
          $$customMarginTop: "16px",
          $$padding: 0,
          $$height_experiment_steps_wrapper: "204px",
        }}
        ref={{
          current: "[Circular]" as any,
        }}
        border="medium"
        elevated="small"
      >
        <div>
          <ExperimentMutationStyles.ExperimentFormHeader>
            <Heading size="H3">
              <FormattedMessage id="create_and_update_experiment.page_title_section.step2.traffic_allocation.title" />
            </Heading>
          </ExperimentMutationStyles.ExperimentFormHeader>

          <Paragraph
            style={{ marginTop: "4px" }}
            size="basis"
            colortype="disabled"
          >
            <FormattedMessage id="create_and_update_experiment.page_title_section.step2.traffic_allocation.sub_title" />
          </Paragraph>
          <div style={{ marginTop: "24px" }}>
            <Input
              data-testid="input-audience-rollout"
              id="input-audience-rollout"
              label={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step2.allocation_percentage" })}`}
              sufix="%"
              type="number"
              max="100"
              min="0"
              placeholder={`${intl.formatMessage({ id: "create_and_update_experiment.placeholder.step2.allocation_percentage" })}`}
              name={"trafficPercentage"}
              hasError={inputError?.includes("trafficPercentage")}
              value={audiences?.trafficPercentage}
              errorText={intl.formatMessage({
                id: "create_and_update_experiment.error_messages.allocation_percentage",
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChangeForm("trafficPercentage", e.target.value)
              }
            />
          </div>
        </div>
      </ExperimentMutationStyles.ExperimentStepsCard>
    </ExperimentMutationStyles.ExperimentStepsWrapper>
  );
};

export default Step2;
